import * as React from 'react';
import './hero404.css';
import Hero404Container from './Hero404Container/hero404Container';

const Hero404 = () => (
    <div className="Hero404">
        <Hero404Container />
    </div>
);

export default Hero404;
