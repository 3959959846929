import * as React from 'react';
import './hero404Container.css';
import { ThemeProvider } from 'styled-components';
import original from "react95/dist/themes/original";
import styled from 'styled-components';
import {
    Window,
    WindowContent,
    WindowHeader,
    Button,
    Anchor
  } from 'react95';
import { Link } from 'gatsby';

  const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .window-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .close-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: -1px;
    margin-top: -1px;
    transform: rotateZ(45deg);
    position: relative;
    &:before,
    &:after {
      content: '';
      position: absolute;
      background: ___CSS_0___;
    }
    &:before {
      height: 100%;
      width: 3px;
      left: 50%;
      transform: translateX(-50%);
    }
    &:after {
      height: 3px;
      width: 100%;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
    }
  }`

const Hero404Container = () => (
            <ThemeProvider theme={original}>
    <Wrapper>
        <Window resizable className='window'>
      <WindowHeader className='window-header'>
        <span>Page not found</span>
        <Button>
          <span className='close-icon' />
        </Button>
      </WindowHeader>
      <WindowContent>
        <p>
        But you can always look at <Link to="/"><Anchor>my projects</Anchor></Link>, <Link to="/selfhelp"><Anchor>listen to my podcast</Anchor></Link>, or explore <Link to="/albums"><Anchor>my favorite music albums</Anchor></Link>.
        </p>
        <Link to="/"><Button>Get back</Button></Link>
      </WindowContent>
    </Window>

    </Wrapper>
    </ThemeProvider>
);

export default Hero404Container;
